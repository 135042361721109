<template>
  <div class="LandingCarousel">

    <LandingCarouselBar
      v-on:saveCarouselSlide="saveCarouselSlide($event)">
    </LandingCarouselBar>

    <!-- Data table headers -->
    <va-card title="All Carousel Slides" class="my-3">
      <div class="row align--center">

        <div class="flex xs12 md3 offset--md9">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            noClear
          />
        </div>
      </div>

      <!-- Data Table Contents -->
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        hoverable
        no-data-label="Nothing to show right now :("
        :loading="loading"
      >

        <template slot="image" slot-scope="props">
          <img
            v-bind="slideImage"
            :src="props.rowData.image"
            class="img-fluid"
            :alt="props.rowData.altTag"
          />
        </template>

        <template slot="actions" slot-scope="props">
          <va-popover :message="`${$t('tables.delete')} Carousel Slide ${props.rowData.name}`" placement="left">
            <va-button flat small color="danger" icon="fa fa-trash" v-on:click="deleteLandingCarouselSlide(props.rowData, props.rowIndex)"/>
          </va-popover>
        </template>

      </va-data-table>
    </va-card>

  </div>
</template>

<script>
import { debounce } from 'lodash'
import { carouselSlidesCollection } from '@/firebaseConfig.js'
import LandingCarouselBar from '@/components_tc/settings/LandingCarouselBar.vue'

export default {
  name: 'LandingCarousel',
  components: {
    LandingCarouselBar,
  },
  data () {
    return {
      term: null,
      perPage: '10',
      loading: false,
      carouselSlides: [],
      perPageOptions: ['10', '25', '50', '100'],
      slideImage: { width: 200, height: 100, class: 'm1' },
    }
  },
  computed: {
    LandingCarouselSlides: function () {
      return this.$store.state.landingCarouselSlides
    },
    fields () {
      return [{
        name: '__slot:image',
        title: this.$t('Background Image url'),
        sortField: 'image',
        width: '20%',
      },
      {
        name: 'title',
        title: this.$t('Title Upper'),
        sortField: 'title',
        width: '10%',
      }, {
        name: 'lead',
        title: this.$t('Title Lower'),
        sortField: 'lead',
        width: '10%',
      }, {
        name: 'body',
        title: this.$t('Body'),
        sortField: 'body',
        width: '30%',
      }, {
        name: 'altTag',
        title: this.$t('Alt Tag'),
        sortField: 'altTag',
        width: '20%',
      }, {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.carouselSlides
      }

      return this.carouselSlides.filter(item => {
        return item.name.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  methods: {
    search: debounce(function (term) {
      this.term = term
    }, 400),

    shorten: function (url) {
      return url.slice(0, 25)
    },

    saveCarouselSlide: function (carouselSlide) {
      const _this = this
      carouselSlidesCollection
        .doc(carouselSlide.name)
        .set(carouselSlide)
        .then(() => {
          _this.carouselSlides.push(carouselSlide)
          _this.$store.commit('setLandingCarouselSlides', _this.carouselSlides)
          _this.carouselSlideConfirmation()
        })
    },

    carouselSlideConfirmation () {
      this.showToast(
        'Landing Carousel Updated',
        {
          icon: 'fa-check-circle',
          position: 'bottom-right',
          duration: 2500,
          fullWidth: false,
        },
      )
    },

    deleteLandingCarouselSlide: function (carouselSlideData, carouselSlideIndex) {
      const _this = this
      this.loading = true

      carouselSlidesCollection
        .doc(carouselSlideData.name)
        .delete()
        .then(function () {
          _this.deleteFromTable(carouselSlideData, carouselSlideIndex)
          _this.showToast(
            'LandingCarousel Deleted',
            {
              icon: 'fa-trash',
              position: 'bottom-right',
              duration: 2500,
              fullWidth: false,
            },
          )
        })
        .catch(err => {
          alert(err.message)
          _this.loading = false
        })
    },

    deleteFromTable: function (carouselSlideData, carouselSlideIndex) {
      let realIndex
      const carouselSlideIndexRev = (this.carouselSlides.length - 1) - carouselSlideIndex

      if (carouselSlideData.name === this.carouselSlides[carouselSlideIndex].name) {
        realIndex = carouselSlideIndex
      } else if (carouselSlideData.name === this.carouselSlides[carouselSlideIndexRev].name) {
        realIndex = carouselSlideIndexRev
      }

      this.carouselSlides.splice(realIndex, 1)
      this.$store.commit('setLandingCarouselSlides', this.carouselSlides)
      this.loading = false
    },

    getLandingCarouselSlides: function (event) {
      var _this = this
      this.loading = true

      if (this.LandingCarouselSlides === undefined || this.LandingCarouselSlides.length === 0 || event != null) {
        carouselSlidesCollection
          .get()
          .then(function (dataSnapshot) {
            const jsonLandingCarouselSlides = []

            dataSnapshot.forEach(function (item) {
              var itemData = item.data()
              jsonLandingCarouselSlides.push(itemData)
            })

            _this.carouselSlides = jsonLandingCarouselSlides.sort((a, b) => (a.name > b.name) ? 1 : -1)
            _this.$store.commit('setLandingCarouselSlides', _this.carouselSlides)
            _this.loading = false
          })
          .catch(err => {
            alert(err.message)
          })
      } else {
        this.carouselSlides = this.LandingCarouselSlides
        this.loading = false
      }
    },
  },

  mounted () {
    this.getLandingCarouselSlides(null)
  },
}
</script>
