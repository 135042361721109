<template>
  <div class="LandingCarouselBar">

    <!-- LandingCarousel Form -->
    <form @submit.prevent="saveCarouselSlide">
      <va-card>

        <h1 class="display-4 text--capitalize">Update Landing Carousel Slides</h1>

        <h1 class="mt-3">Slide content</h1>
        <!-- Row 1 -->
        <div class="row">
          <!-- Landing Carousel Slide Title -->
          <div class="flex md6 xs12">
            <va-input
              label="Slide Title Upper"
              v-model="carouselSlide.title"
              placeholder="Type Slide Title Upper here"
            >
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-text-height"
              />
            </va-input>
          </div>

          <!-- Landing Carousel Slide Lead -->
          <div class="flex md6 xs12">
            <va-input
              label="Slide Title Lower"
              v-model="carouselSlide.lead"
              placeholder="Type Slide Title Lower here">
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-file-text"
              />
            </va-input>
          </div>
        </div>

        <!-- Row 2 -->
        <div class="row">
          <!-- Landing Carousel Slide Body -->
          <div class="flex md6 xs12">
            <va-input
              label="Slide Body Text"
              v-model="carouselSlide.body"
              placeholder="Type Slide Body here"
            >
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-text-height"
              />
            </va-input>
          </div>

          <!-- Landing Carousel Slide Name -->
          <div class="flex md6 xs12">
            <va-input
              label="Carousel Slide Name"
              v-model="carouselSlide.name"
              placeholder="Type Slide Name here">
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-address-book"
              />
            </va-input>
          </div>
        </div>

        <!-- Carousel Slide Background Image -->
        <h1>Slide Background Image</h1>
        <div class="row">
          <div class="flex md8 xs12">
            <va-input
              label="Background Image URL"
              v-model="carouselSlide.image"
              placeholder="Type Slide Image URL here">
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-image"
              />
            </va-input>
          </div>

          <!-- Landing Carousel Slide Alt tag -->
          <div class="flex md4 xs12">
            <va-input
              label="Slide Alt Tag"
              v-model="carouselSlide.altTag"
              placeholder="Type Slide Alt Tag here">
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-tag"
              />
            </va-input>
          </div>
        </div>

        <!-- Save Landing Carousel Slide -->
        <div class="row">
          <div class="flex xs6 md9">
          </div>
          <div class="flex xs6 md3">
            <va-button
              medium
              color="info"
              icon="fa fa-bolt"
              type="submit">
              Save Slide
            </va-button>
          </div>
        </div>

      </va-card>
    </form>
  </div>
</template>

<script>

export default {
  name: 'LandingCarouselBar',
  data () {
    return {
      carouselSlide: {
        name: '',
        body: '',
        lead: '',
        title: '',
        image: '',
        altTag: '',
        item: 'TurtleCreek-Food',
      },
    }
  },
  methods: {
    saveCarouselSlide: function () {
      if (this.validateSlideText()) {
        this.$emit('saveCarouselSlide', this.carouselSlide)
        this.resetLandingCarousel()
      }
    },

    validateSlideText: function () {
      this.carouselSlide.body = this.carouselSlide.body.toUpperCase()
      this.carouselSlide.lead = this.carouselSlide.lead.toUpperCase()
      this.carouselSlide.title = this.carouselSlide.title.toUpperCase()
      this.carouselSlide.name = this.carouselSlide.name.split(' ').join('')
      return this.carouselSlide.image.length > 1
    },

    resetLandingCarousel: function () {
      this.carouselSlide = {
        name: '',
        lead: '',
        title: '',
        image: '',
      }
    },
  },
}
</script>
